/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { graphql } from 'gatsby';
import React from 'react';

import {
  CoverageLogos,
  CoverageLogosContentProps,
} from 'components/contents/Press/CoverageLogos';
import { Features, FeaturesProps } from 'components/contents/Press/Features';
import { Hero, HeroProps } from 'components/contents/Press/Hero';
import { Mission, MissionProps } from 'components/contents/Press/Mission';
import {
  Photography,
  PhotographyProps,
} from 'components/contents/Press/Photography';
import Layout from 'components/partials/Layout';
import { Overflow } from 'components/partials/Overflow';
import { PressSlider } from 'components/partials/PressSlider';

interface Props {
  data: {
    pressYaml: {
      metadata: MetaData;
      hero: HeroProps['content'];
      mission: MissionProps['content'];
      features: FeaturesProps;
      photography: PhotographyProps['content'];
      coverageAndLogos: CoverageLogosContentProps['content'];
    };
  };
}

const Press: React.FC<Props> = ({ data }) => {
  return (
    <Overflow>
      <Layout
        title={data.pressYaml.metadata.title}
        description={data.pressYaml.metadata.description}
      >
        <Hero content={data.pressYaml.hero} />
        <Mission content={data.pressYaml.mission} />
        <Features
          slider={data.pressYaml.features.slider}
          title={data.pressYaml.features.title}
        />
        <Photography content={data.pressYaml.photography} />
        <CoverageLogos content={data.pressYaml.coverageAndLogos} />
        <PressSlider />
      </Layout>
    </Overflow>
  );
};

export const query = graphql`
  query PressQuery {
    pressYaml {
      metadata {
        title
        description
      }
      ...PressHero
      ...PressMission
      ...PressFeatures
      ...PressPhotography
      ...PressHeroCoverage
    }
  }
`;

export default Press;
