/*
 * Copyright (C) 2019 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import {
  Col,
  Container,
  DownloadableWrapper,
  Link,
  Typography,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

const CoverageLogosSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  marginTop: theme.spacing(10),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(19),
    marginTop: theme.spacing(40),
  },
}));

const CoverageLogosContainer = styled(Container)(({ theme }) => ({
  '& > div': {
    display: 'flex',
    flexDirection: 'column',
  },

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& > div': {
      flexDirection: 'row',
    },
  },
}));

const CoverageLogosItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(8),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
    marginBottom: theme.spacing(15.75),
  },
}));

const CoverageItem = styled('a')(({ theme }) => ({
  '& > div': {
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '&:hover': {
    '& > div': {
      color: theme.palette.secondary.dark,
    },
  },
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  color: theme.palette.primary.dark,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  maxWidth: 'unset',
  textDecoration: 'none',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: theme.typography.pxToRem(572),
  },
}));

const CoverageItemContent = styled('div')(({ theme }) => ({
  padding: theme.spacing(2.25, 0),
}));

const CoverageItemContentTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,

  [theme.breakpoints.up(Breakpoints.Md)]: {},
}));

const LogosHead = styled('div')(({ theme }) => ({
  '& a': {
    paddingTop: theme.spacing(2.75),
  },
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: theme.spacing(20),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: 0,
  },
}));

const LogoWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  width: '100%',

  [theme.breakpoints.up(710)]: {
    justifyContent: 'space-between',
  },

  [theme.breakpoints.up(Breakpoints.Md)]: {
    justifyContent: 'space-between',
    marginTop: theme.spacing(3.75),
  },
}));

const LogosCol = styled(Col)(({ theme }) => ({
  display: 'contents',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'block',
    marginLeft: theme.spacing(3),
    maxWidth: `calc(50% - ${theme.typography.pxToRem(12)})`,
  },
}));

const LogoImage = styled('div')<
  Theme,
  {
    bgImage: string;
  }
>(({ theme, bgImage }) => ({
  backgroundImage: `url(${bgImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.borderRadius[1],
  height: theme.typography.pxToRem(320),
  marginBottom: theme.spacing(6),
  maxHeight: theme.typography.pxToRem(320),
  maxWidth: '100%',
  overflow: 'hidden',
  width: '100%',

  [theme.breakpoints.up(420)]: {
    height: theme.typography.pxToRem(308),
    maxHeight: theme.typography.pxToRem(308),
    maxWidth: theme.typography.pxToRem(320),
  },

  [theme.breakpoints.up(710)]: {
    '&:nth-child(odd)': {
      marginRight: theme.spacing(7.5),
    },
  },

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '&:nth-child(odd)': {
      marginRight: 0,
    },
    height: '22vw',
    maxHeight: theme.typography.pxToRem(308),
    maxWidth: 'calc(50% - 12px)',
  },
}));

const LogosTitleLink = styled(Link)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'block',
  },
}));

const LogosFooterLink = styled(Link)(({ theme }) => ({
  '& span ': {
    ...theme.typography.body1,
  },
  display: 'block',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'none',
  },
}));

const CoverageLogos: React.FC<CoverageLogosContentProps> = ({ content }) => {
  return (
    <CoverageLogosSection>
      <CoverageLogosContainer>
        <Col md={6}>
          <CoverageLogosItemTitle variant="h4">
            {content.coverage.title}
          </CoverageLogosItemTitle>
          {content.coverage.list.map((item) => {
            // List of Coverage documents
            return (
              <CoverageItem
                download
                href={item.linkUrl.publicURL}
                key={item.title}
              >
                <CoverageItemContent>
                  <CoverageItemContentTitle>
                    {item.title}
                  </CoverageItemContentTitle>
                </CoverageItemContent>
                <Link
                  withoutHover
                  component="div"
                  download
                  to={item.linkUrl.publicURL}
                >
                  {item.linkText}
                </Link>
              </CoverageItem>
            );
          })}
        </Col>
        <LogosCol md={6}>
          <LogosHead>
            <CoverageLogosItemTitle variant="h4">
              {content.logos.title}
            </CoverageLogosItemTitle>
            <LogosTitleLink download to={content.logos.allUrl.publicURL}>
              {content.logos.allText}
            </LogosTitleLink>
          </LogosHead>
          <LogoWrapper>
            {content.logos.list.map((logo) => {
              // List of Airfordable logos
              return (
                <LogoImage
                  key={logo.childImageSharp.original.src}
                  bgImage={logo.childImageSharp.original.src}
                >
                  <DownloadableWrapper to={logo.childImageSharp.original.src}>
                    <GatsbyImage
                      alt=""
                      image={logo.childImageSharp.gatsbyImageData}
                    />
                  </DownloadableWrapper>
                </LogoImage>
              );
            })}
          </LogoWrapper>
          <LogosFooterLink download to={content.logos.allUrl.publicURL}>
            {content.logos.allText}
          </LogosFooterLink>
        </LogosCol>
      </CoverageLogosContainer>
    </CoverageLogosSection>
  );
};

export interface CoverageLogosContentProps {
  content: {
    coverage: {
      title: string;
      list: {
        linkText: string;
        title: string;
        linkUrl: {
          publicURL: string;
        };
      }[];
    };

    logos: {
      allText: string;
      title: string;
      list: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
          original: IImage;
        };
      }[];
      allUrl: {
        publicURL: string;
      };
    };
  };
}

const query = graphql`
  fragment PressHeroCoverage on PressYaml {
    coverageAndLogos {
      coverage {
        title
        list {
          linkText
          title
          linkUrl {
            publicURL
          }
        }
      }
      logos {
        allText
        title
        list {
          childImageSharp {
            gatsbyImageData
            original {
              width
              height
              src
            }
          }
        }
        allUrl {
          publicURL
        }
      }
    }
  }
`;

export { CoverageLogos, query };
