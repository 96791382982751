/*
 * Copyright (C) 2020 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { Theme, withTheme } from '@material-ui/core';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';

import {
  Card,
  Carousel,
  CarouselDot,
  CarouselDotContainer,
  CarouselRenderDotProps,
  Col,
  Container,
  Link,
  Typography,
  Wrapper,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

export interface CarouselItemsProps {
  title?: string;
  testimonial: string;
  image: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
  logo?: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
  linkUrl?: string;
  linkText?: string;
}

interface CarouselProps {
  headComponent?: React.ReactNode;
  slider: CarouselItemsProps[];
  theme: Theme;
}

const DotText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 500,
}));

const renderDot = ({ index, isActive, goTo }: CarouselRenderDotProps) => {
  return (
    <CarouselDot key={index} isActive={isActive} onClick={() => goTo(index)}>
      <DotText>{index + 1}</DotText>
    </CarouselDot>
  );
};

const CarouselSection = styled('section')({
  position: 'relative',
});

const CarouselWrapper = styled(Wrapper)(({ theme }) => ({
  padding: theme.spacing(7.5, 0, 24.5),
  position: 'relative',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    padding: theme.spacing(20, 0),
  },
}));

const CarouselImage = styled(GatsbyImage)(({ theme }) => ({
  borderRadius: theme.borderRadius[1],
  height: theme.typography.pxToRem(280),
  maxWidth: theme.typography.pxToRem(1068),
  overflow: 'hidden',
  width: '100%',
  zIndex: -1,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    height: theme.typography.pxToRem(400),
  },
}));

const CarouselCard = styled(Card)(({ theme }) => ({
  marginLeft: '0%',
  maxWidth: '100%',
  padding: theme.spacing(7.5, 7.5, 10),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginLeft: '16.66%',
    maxWidth: theme.typography.pxToRem(1024),
    padding: theme.spacing(10),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 500,
  marginBottom: theme.spacing(4),
  maxWidth: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: theme.typography.pxToRem(260),
    ...theme.typography.h4,
    fontWeight: 500,
  },
}));

const SliderLogoWrapper = styled(Wrapper)(({ theme }) => ({
  '& .gatsby-image-wrapper': {
    maxWidth: theme.typography.pxToRem(90),
  },

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(6),
  maxWidth: '100%',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
    maxWidth: theme.typography.pxToRem(228),
  },
}));

const SliderLogoLink = styled(Link)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    alignItems: 'center',
    display: 'flex',
  },
}));

const SliderLogoLinkMobile = styled(Link)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: theme.spacing(6),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'none',
  },
}));

const SliderText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  marginLeft: 0,
  maxWidth: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    fontSize: '1.2em',
    marginLeft: theme.spacing(5),
    maxWidth: theme.typography.pxToRem(608),
  },
}));

const SliderTestimonial = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
  },
}));

const SliderCol = styled(Col)(({ theme }) => ({
  marginTop: theme.spacing(-5),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: theme.spacing(-25),
  },
}));

const MainSlider: React.FC<CarouselProps> = ({
  headComponent,
  slider,
  theme,
}) => {
  return (
    <CarouselSection>
      {headComponent}
      <Container>
        <CarouselWrapper>
          <Carousel
            dots
            vertical
            renderDot={renderDot}
            renderDotsContainer={CarouselDotContainer}
            autoPlay={false}
          >
            {slider.map((sliderItem) => {
              return (
                <div key={sliderItem.title}>
                  <Col md={10}>
                    <CarouselImage
                      alt=""
                      image={sliderItem.image.childImageSharp.gatsbyImageData}
                    />
                  </Col>

                  <SliderCol md={12}>
                    <CarouselCard bgColor={theme.palette.brandRed500}>
                      <SliderTestimonial>
                        {sliderItem.title && (
                          <Title variant="body1">{sliderItem.title}</Title>
                        )}
                        {sliderItem.logo && (
                          <SliderLogoWrapper>
                            <GatsbyImage
                              image={
                                sliderItem.logo.childImageSharp.gatsbyImageData
                              }
                              alt="Slider Logo"
                            />
                            <SliderLogoLink to={sliderItem.linkUrl}>
                              <Typography variant="body1">
                                {sliderItem.linkText}
                              </Typography>
                            </SliderLogoLink>
                          </SliderLogoWrapper>
                        )}
                        <SliderText variant="body1">
                          {sliderItem.testimonial}
                        </SliderText>
                        {sliderItem.logo && (
                          <SliderLogoLinkMobile to={sliderItem.linkUrl}>
                            <Typography variant="body1">
                              {sliderItem.linkText}
                            </Typography>
                          </SliderLogoLinkMobile>
                        )}
                      </SliderTestimonial>
                    </CarouselCard>
                  </SliderCol>
                </div>
              );
            })}
          </Carousel>
        </CarouselWrapper>
      </Container>
    </CarouselSection>
  );
};

const Slider = withTheme(MainSlider);

export { Slider };
