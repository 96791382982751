/*
 * Copyright (C) 2019 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import { Col, Container, Typography, Wrapper } from 'components/UI';
import { Breakpoints } from 'utils/enums';

// Types

// Components

const MissionSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(3.875),
  marginTop: theme.spacing(4.625),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(46.75),
    marginTop: theme.spacing(10),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'block',
    maxWidth: theme.typography.pxToRem(396),
  },
}));

const MissionItem = styled(Wrapper)(({ theme }) => ({
  '&:last-child': {
    paddingbottom: 0,
  },

  paddingBottom: theme.spacing(8),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '&:nth-child(even)': {
      paddingRight: 0,
    },

    maxWidth: theme.typography.pxToRem(396),
    paddingRight: theme.spacing(5.5),
  },
}));

const MissionItemTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: theme.spacing(2),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h4,
    fontWeight: 500,
    marginBottom: theme.spacing(4),
  },
}));

const MissionItemText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: theme.typography.h4,
}));

const MissionCol = styled(Col)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const Mission: React.FC<MissionProps> = ({ content }) => {
  return (
    <MissionSection>
      <Container>
        <Col md={4}>
          <Title variant="h2">{content.title}</Title>
        </Col>
        <MissionCol md={8}>
          {content.list.map((mission) => {
            return (
              <MissionItem key={mission.title}>
                <MissionItemTitle variant="body1">
                  {mission.title}
                </MissionItemTitle>
                <MissionItemText variant="body1">
                  {mission.text}
                </MissionItemText>
              </MissionItem>
            );
          })}
        </MissionCol>
      </Container>
    </MissionSection>
  );
};

export interface MissionProps {
  content: {
    title: string;
    list: {
      title: string;
      text: string;
    }[];
  };
}

const query = graphql`
  fragment PressMission on PressYaml {
    mission {
      title
      list {
        title
        text
      }
    }
  }
`;

export { Mission, query };
