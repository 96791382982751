/*
 * Copyright (C) 2019 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled, Theme } from '@material-ui/core';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import {
  Container,
  DownloadableWrapper,
  Link,
  Typography,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

const PhotographySection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(10),
  marginTop: theme.spacing(10),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(20),
    marginTop: theme.spacing(20),
  },
}));

const PhotographyHead = styled('header')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(6),
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    alignItems: 'center',
    marginBottom: theme.spacing(20),
  },
}));

const PhotographyTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
  },
}));

const PhotographyTitleLink = styled(Link)(({ theme }) => ({
  '& span ': {
    ...theme.typography.body2,
  },
  display: 'none',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    '& span ': {
      ...theme.typography.body1,
    },
    display: 'block',
  },
}));

const PhotographyFooterLink = styled(Link)(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(6),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    display: 'none',
  },
}));

const PhotographyGrid = styled('section')(({ theme }) => ({
  display: 'grid',
  gridColumnGap: theme.typography.pxToRem(16),
  gridRowGap: theme.typography.pxToRem(16),
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: `repeat(auto-fill, minmax(${theme.typography.pxToRem(
    40
  )}, ${theme.typography.pxToRem(40)}))`,
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    gridColumnGap: theme.typography.pxToRem(23),
    gridRowGap: theme.typography.pxToRem(23),
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
  },
}));

const PhotographyFirst = styled('figure')<
  Theme,
  {
    bgImage: string;
    index?: number;
  }
>(({ theme, bgImage }) => ({
  '& .gatsby-image-wrapper': {
    opacity: '0',
  },

  backgroundImage: `url(${bgImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  borderRadius: theme.borderRadius[1],
  gridColumn: '1 / span 1',
  gridRow: 'span 5',
  height: theme.typography.pxToRem(280),
  margin: '0',
  overflow: 'hidden',
  width: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    gridColumn: '1 / span 2',
    gridRow: 'unset',
    height: theme.typography.pxToRem(280),
  },
}));

const PhotographyLast = styled('figure')<
  Theme,
  { bgImage: string; index: number }
>(({ theme, bgImage, index = 0 }) => {
  const sum = () => (index === 6 ? 1 : 6);

  return {
    '& .gatsby-image-wrapper': {
      opacity: '0',
    },

    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: theme.borderRadius[1],
    gridColumn: '2 / span 1',
    gridRow: `${index + sum()} / span 5 `,
    height: theme.typography.pxToRem(280),
    margin: '0',
    overflow: 'hidden',
    width: '100%',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      gridColumn: '3 / span 2',
      gridRow: 'unset',
      height: theme.typography.pxToRem(280),
    },
  };
});

const PhotographyElse = styled('figure')<Theme, { bgImage: string }>(
  ({ theme, bgImage }) => ({
    '& .gatsby-image-wrapper': {
      opacity: '0',
    },

    backgroundImage: `url(${bgImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: theme.borderRadius[1],
    gridRow: 'span 3',
    height: theme.typography.pxToRem(159),
    margin: '0',
    maxWidth: theme.typography.pxToRem(359),
    overflow: 'hidden',
    width: '100%',

    [theme.breakpoints.up(Breakpoints.Md)]: {
      gridRow: 'span 1',
      height: theme.typography.pxToRem(280),
      maxWidth: theme.typography.pxToRem(359),
    },
  })
);

const DownloadableWrapperCommon: React.FC<{
  photo: {
    image: {
      childImageSharp: { gatsbyImageData: IGatsbyImageData; original: IImage };
    };
  };
}> = ({ photo }) => {
  const photoSrc = photo.image.childImageSharp;
  return (
    <DownloadableWrapper to={photoSrc.original.src}>
      <GatsbyImage alt="" image={photoSrc.gatsbyImageData} />
    </DownloadableWrapper>
  );
};

const Photography: React.FC<PhotographyProps> = ({ content }) => {
  return (
    <PhotographySection>
      <Container>
        <PhotographyHead>
          <PhotographyTitle variant="h4">{content.title}</PhotographyTitle>
          <PhotographyTitleLink download to={content.allUrl.publicURL}>
            {content.allText}
          </PhotographyTitleLink>
        </PhotographyHead>
        <PhotographyGrid>
          {content.list.map((photo, index) => {
            const currentIndex = index + 1;
            const photoSrc = photo.image.childImageSharp.original.src;

            if (currentIndex === 1 || currentIndex % 7 === 0) {
              return (
                <PhotographyFirst
                  bgImage={photoSrc ? photoSrc : ''}
                  key={photoSrc}
                >
                  <DownloadableWrapperCommon photo={photo} />
                </PhotographyFirst>
              );
            }
            if (currentIndex === 6 || currentIndex % 6 === 0) {
              return (
                <PhotographyLast
                  index={currentIndex}
                  bgImage={photoSrc ? photoSrc : ''}
                  key={photoSrc}
                >
                  <DownloadableWrapperCommon photo={photo} />
                </PhotographyLast>
              );
            }
            return (
              <PhotographyElse
                bgImage={photoSrc ? photoSrc : ''}
                key={photoSrc}
              >
                <DownloadableWrapperCommon photo={photo} />
              </PhotographyElse>
            );
          })}
        </PhotographyGrid>
        <PhotographyFooterLink
          variant="h2"
          download
          to={content.allUrl.publicURL}
        >
          {content.allText}
        </PhotographyFooterLink>
      </Container>
    </PhotographySection>
  );
};

export interface PhotographyProps {
  content: {
    list: {
      image: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
          original: IImage;
        };
      };
    }[];
    allUrl: {
      publicURL: string;
    };
    allText: string;
    title: string;
  };
}

const query = graphql`
  fragment PressPhotography on PressYaml {
    photography {
      list {
        image {
          childImageSharp {
            gatsbyImageData
            original {
              height
              width
              src
            }
          }
        }
      }
      allUrl {
        publicURL
      }
      allText
      title
    }
  }
`;

export { Photography, query };
