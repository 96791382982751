/*
 * Copyright (C) 2019 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import React from 'react';

import { CarouselItemsProps, Slider } from 'components/partials/Slider';
import { Col, Container, Typography } from 'components/UI';
import { Breakpoints } from 'utils/enums';

const FeaturesHeader = styled('header')(({ theme }) => ({
  margin: `${theme.spacing(20)} auto ${theme.spacing(20)}`,

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
  },
}));

const FeaturesWrapper = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    flexDirection: 'row',
  },
}));

const FeaturesTitle = styled(Typography)(({ theme }) => ({
  maxWidth: '100%',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h2,
    maxWidth: theme.typography.pxToRem(400),
  },
}));

const Features: React.FC<FeaturesProps> = ({ slider, title }) => {
  // Component Header
  const HeadComponent: React.FC = () => {
    return (
      <FeaturesHeader>
        <FeaturesWrapper>
          <Col md={12}>
            <FeaturesTitle variant="h4">{title}</FeaturesTitle>
          </Col>
        </FeaturesWrapper>
      </FeaturesHeader>
    );
  };

  return <Slider slider={slider} headComponent={<HeadComponent />} />;
};

export interface FeaturesProps {
  slider: CarouselItemsProps[];
  title: string;
}

const query = graphql`
  fragment PressFeatures on PressYaml {
    features {
      title
      slider {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        linkUrl
        linkText
        testimonial
        logo {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export { Features, query };
