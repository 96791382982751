/*
 * Copyright (C) 2019 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { styled } from '@material-ui/core';
import { graphql } from 'gatsby';
import { IGatsbyImageData, IImage } from 'gatsby-plugin-image';
import React from 'react';

import { Col, Container, Illustration, Typography } from 'components/UI';
import { Breakpoints } from 'utils/enums';
import { textByLine } from 'utils/textByLine';

const HeroImages = styled(Col)(({ theme }) => ({
  '& .image': {
    position: 'absolute !important' as any,
  },
  '& .image:nth-child(1)': {
    '& .asset': {
      width: '130%',
    },
    left: '35%',
    top: 0,
    width: '36%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      left: 0,
      top: '20%',
      width: theme.spacing(50),
    },
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      width: theme.spacing(57),
    },
  },
  '& .image:nth-child(2)': {
    '& .asset': {
      left: '-18%',
      top: '-7%',
      width: '162%',
    },
    bottom: 0,
    left: 0,
    width: '36%',
    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: 'auto',
      left: 'auto',
      right: '10%',
      top: '10%',
      width: theme.spacing(50),
    },
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      right: '20%',
      width: theme.spacing(57),
    },
  },
  '& .image:nth-child(3)': {
    '& .asset': {
      left: '28%',
      top: '55%',
      width: '85%',
    },
    right: 0,
    top: '20%',
    width: '36%',
    zIndex: 9,
    [theme.breakpoints.up(Breakpoints.Md)]: {
      bottom: 0,
      right: 0,
      top: 'auto',
      width: theme.spacing(50),
    },
    [theme.breakpoints.up(Breakpoints.Lg)]: {
      width: theme.spacing(57),
    },
  },
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginTop: 0,
    minWidth: 'auto',
    paddingTop: '0 !important' as any,
  },
  marginTop: theme.spacing(12),
  minWidth: '100%',
  paddingTop: '120% !important' as any,
  position: 'relative',
}));

const HeroSection = styled('section')(({ theme }) => ({
  marginBottom: theme.spacing(4.625),
  position: 'relative',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.spacing(5),
  },
}));

const Content = styled(Col)(({ theme }) => ({
  flex: 1,
  marginBottom: theme.typography.pxToRem(0),
  marginTop: theme.typography.pxToRem(0),

  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: theme.typography.pxToRem(379),
    marginTop: theme.typography.pxToRem(120),
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginBottom: '1em',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h1,
    width: '60%',
  },
}));

const ContactText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up(Breakpoints.Md)]: {
    ...theme.typography.h4,
  },
}));

const Hero: React.FC<HeroProps> = ({ content }) => {
  return (
    <HeroSection>
      <Container>
        <Content md={5}>
          <Title variant="h4">{content.title}</Title>

          {textByLine(content.text).map((item) => (
            <ContactText key={item} variant="body1">
              {item}
            </ContactText>
          ))}
        </Content>
        <HeroImages md={7}>
          {content.images.map((item, index) => {
            return (
              <Illustration
                alt=""
                className="image"
                key={`image-${index}`}
                image={item.main.childImageSharp.gatsbyImageData}
                asset={item.asset?.childImageSharp.original.src}
                assetAspectRatio={
                  item.asset
                    ? item.asset?.childImageSharp.original.width /
                      item.asset?.childImageSharp.original.height
                    : undefined
                }
              />
            );
          })}
        </HeroImages>
      </Container>
    </HeroSection>
  );
};

export interface HeroProps {
  content: {
    text: string;
    title: string;
    images: {
      main: { childImageSharp: { gatsbyImageData: IGatsbyImageData } };
      asset: { childImageSharp: { original: IImage } };
    }[];
  };
}

const query = graphql`
  fragment PressHero on PressYaml {
    hero {
      text
      title
      images {
        main {
          childImageSharp {
            gatsbyImageData
          }
        }
        asset {
          childImageSharp {
            original {
              height
              width
              src
            }
          }
        }
      }
    }
  }
`;

export { Hero, query };
